import React from 'react';

import { Box } from '@waglabs/box-next';
import { Grid } from '@waglabs/grid';
import { LazyImage } from '@waglabs/lazyload';
import Link from '@waglabs/link';
import { Typography } from '@waglabs/typography';

import { isEven } from '../../utils';

export type ExecutiveProps = {
  alt: string;
  href: string;
  index: number;
  name: string;
  src: string;
  position: string;
};

export const Executive = ({
  alt,
  href,
  index,
  name,
  src,
  position,
}: ExecutiveProps) => (
  <Grid
    gridTemplateColumns={isEven(index) ? ['120px 1fr', 'auto'] : ['1fr 120px', 'auto']}
    gridGap={['16px', 'initial']}
    alignItems="center"
    width={[1, '200px']}
    m={[0, '24px', '24px', '54px']}
  >
    {/*
      Old style approach

      Seems like the Box component doesn't support
      the CSS properties `gridColumnStart` and `gridRowStart`.

      TODO - Update wag-react Box to support these CSS grid properties
      using styled-system `system` fn
    */}
    <Link
      display={['block', 'none']}
      href={href}
      target="_blank"
      style={{
        gridColumnStart: isEven(index) ? 1 : 2,
        gridRowStart: isEven(index) ? 0 : 1,
      }}
    >
      <LazyImage
        src={src}
        alt={alt}
        width={['120px', '200px']}
        height={['120px', '200px']}
      />
    </Link>
    <Link
      display={['none', 'block']}
      href={href}
      target="_blank"
    >
      <LazyImage
        src={src}
        alt={alt}
        width={['120px', '200px']}
        height={['120px', '200px']}
      />
    </Link>

    <Box
      height="80px"
    >
      <Typography
        fontWeight="bold"
        fontSize="18px"
        my={1}
        textAlign={isEven(index) ? ['left', 'center'] : ['right', 'center']}
      >
        <Link
          href={href}
          target="_blank"
          color="wagGreen"
        >
          {name}
        </Link>
      </Typography>
      <Typography
        m={0}
        textAlign={isEven(index) ? ['left', 'center'] : ['right', 'center']}
        color="#474747"
      >
        {position}
      </Typography>
    </Box>
  </Grid>
);
