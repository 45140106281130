// @flow
import alert from './assets/alert.svg';
import appStore from './assets/app-store.svg';
import boardingService from './assets/boarding-service.svg';
import bulletGreen from './assets/bullet-green.svg';
import boneGray from './assets/bone-gray.svg';
import calendar from './assets/calendar.svg';
import calendarGray from './assets/calendar-gray.svg';
import calendarGreen from './assets/calendar-green.svg';
import camera from './assets/camera.svg';
import car from './assets/car.svg';
import caret from './assets/caret.svg';
import caretDownGray from './assets/caret-down-gray.svg';
import caretDownGreen from './assets/caret-down-green.svg';
import caretRightGray from './assets/caret-right-gray.svg';
import check from './assets/check.svg';
import checkmark from './assets/checkmark.png';
import checkmarkGreen from './assets/checkmark-green.svg';
import clockGray from './assets/clock-gray.svg';
import closeIconGray from './assets/close-icon-gray.svg';
import collar from './assets/collar.svg';
import creditCard from './assets/credit-card.svg';
import daycareService from './assets/daycare-service.svg';
import disabledCaret from './assets/caret-disabled.svg';
import disabledCheck from './assets/check-disabled.svg';
import dog from './assets/dog.svg';
import dogAvatar from './assets/dog-avatar.svg';
import dogFaceGray from './assets/dog-face-gray.svg';
import dogGreen from './assets/dog-green.svg';
import dogSilhouetteGray from './assets/dog-silhouette-gray.svg';
import dollarSignGray from './assets/dollar-sign-gray.svg';
import email from './assets/email.svg';
import envelope from './assets/envelope.svg';
import endorse from './assets/endorse.svg';
import exitGray from './assets/exit-gray.svg';
import eyeball from './assets/eyeball.png';
import facebook from './assets/facebook.svg';
import facebookCircle from './assets/facebook-circle.svg';
import flyer from './assets/flyer.svg';
import googlePlay from './assets/google-play.svg';
import hamburger from './assets/hamburger.svg';
import home from './assets/home.svg';
import house from './assets/house.svg';
import houseGreen from './assets/house-green.svg';
import imageGray from './assets/image-gray.svg';
import infoBlue from './assets/info-blue.png';  // png because the svg is broken, maybe due to fonts
import infoGreen from './assets/info-green.svg';
import infoWhite from './assets/info-white.svg';
import instagram from './assets/instagram.svg';
import invite from './assets/invite.svg';
import key from './assets/key.svg';
import lock from './assets/lock.svg';
import lockGray from './assets/lock-gray.svg';
import medicalGray from './assets/medical-gray.svg';
import noteGray from './assets/note-gray.svg';
import overnightService from './assets/overnight-service.svg';
import pageNote from './assets/page-note.svg';
import pawPrint from './assets/paw-print.svg';
import pawPrintPinGray from './assets/paw-print-pin-gray.svg';
import paymentGray from './assets/payment-gray.svg';
import pencil from './assets/pencil.svg';
import pencilGreen from './assets/pencil-green.svg';
import phone from './assets/phone.svg';
import phoneCall from './assets/phone-call.svg';
import plusGreen from './assets/plus-green.svg';
import premiumStar  from './assets/premium-star.svg';
import silhouette from './assets/silhouette.svg';
import silhouetteGirl1 from './assets/silhouette-girl1.svg';
import silhouetteGirl2 from './assets/silhouette-girl2.svg';
import silhouetteGirl3 from './assets/silhouette-girl3.svg';
import silhouetteGirl4 from './assets/silhouette-girl4.svg';
import silhouetteGirl5 from './assets/silhouette-girl5.svg';
import silhouetteGuy1 from './assets/silhouette-guy1.svg';
import silhouetteGuy2 from './assets/silhouette-guy2.svg';
import silhouetteGuy3 from './assets/silhouette-guy3.svg';
import silhouetteGuy4 from './assets/silhouette-guy4.svg';
import silhouetteGuy5 from './assets/silhouette-guy5.svg';
import sittingService from './assets/sitting-service.svg';
import sms from './assets/sms.svg';
import star from './assets/star.svg';
import stethoscope from './assets/stethoscope.svg';
import stethoscopeWhite from './assets/stethoscope-white.svg';
import thirtyMinuteWalk from './assets/thirty-minute-walk.svg';
import threeSpeckled from './assets/threeSpeckled.svg';
import tiktok from './assets/tiktok.svg';
import trash from './assets/trash.svg';
import twitter from './assets/twitter.svg';
import twitterCircle from './assets/twitter-circle.svg';
import wagLogo from './assets/wag-logo.svg';
import wagTag from './assets/wag-tag.svg';
import wagTextLogo from './assets/wag-text-only-logo.svg';
import walker from './assets/walker.svg';
import walkerSilhouetteGray from './assets/walker-silhouette-gray.svg';
import walkService from './assets/walk-service.svg';
import weightGray from './assets/weight-gray.svg';

export const icons = {
  alert,
  appStore,
  boardingService,
  boneGray,
  bulletGreen,
  calendar,
  calendarGray,
  calendarGreen,
  camera,
  car,
  carat: caret,
  caratDownGray: caretDownGray,
  caratDownGreen: caretDownGreen,
  caratRightGray: caretRightGray,
  caret,
  caretDownGray,
  caretDownGreen,
  caretRightGray,
  check,
  checkmark,
  checkmarkGreen,
  clockGray,
  closeIconGray,
  collar,
  creditCard,
  daycareService,
  default: dog,
  disabledCarat: disabledCaret,
  disabledCaret,
  disabledCheck,
  dog,
  dogAvatar,
  dogFaceGray,
  dogGreen,
  dogSilhouetteGray,
  dollarSignGray,
  email,
  endorse,
  envelope,
  exitGray,
  eyeball,
  facebook,
  facebookCircle,
  flyer,
  googlePlay,
  hamburger,
  home,
  house,
  houseGreen,
  imageGray,
  infoBlue,
  infoGreen,
  infoWhite,
  instagram,
  invite,
  key,
  lock,
  lockGray,
  medicalGray,
  noteGray,
  overnightService,
  pageNote,
  pawPrint,
  pawPrintPinGray,
  paymentGray,
  premiumStar,
  pencil,
  pencilGreen,
  phone,
  phoneCall,
  plusGreen,
  silhouette,
  silhouetteGirl1,
  silhouetteGirl2,
  silhouetteGirl3,
  silhouetteGirl4,
  silhouetteGirl5,
  silhouetteGuy1,
  silhouetteGuy2,
  silhouetteGuy3,
  silhouetteGuy4,
  silhouetteGuy5,
  sittingService,
  sms,
  star,
  stethoscope,
  stethoscopeWhite,
  thirtyMinuteWalk,
  threeSpeckled,
  tiktok,
  trash,
  twitter,
  twitterCircle,
  wagLogo,
  wagTag,
  wagTextLogo,
  walker,
  walkerSilhouetteGray,
  walkService,
  weightGray,
};

export default icons;
