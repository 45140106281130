// @flow
import React from 'react';
import { Image } from '@waglabs/image-next';
import { getIconByName } from './getIconByName';

type Props = {
  isInverted?: boolean,
  name?: string,
};

export const Icon = ({
  name,
  isInverted = false,
  ...props
}: Props) => (
  <Image
    transform={isInverted ? 'rotate(180deg)' : undefined} // Console errors if we don't do it this way
    src={getIconByName(name)}
    {...props}
  />
);
