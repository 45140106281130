import React from 'react';

import { Box } from '@waglabs/box-next';
import { Container } from '@waglabs/container';
import { LazyImage } from '@waglabs/lazyload';
import { Typography } from '@waglabs/typography';
import { useGetResponsivePropValue } from '@waglabs/use-get-responsive-prop-value';

import investorsDesktop from '../../../../assets/partners/investors-desktop.png';
import investorsMobile from '../../../../assets/partners/investors-mobile.png';
import partnersDesktop from '../../../../assets/partners/partners-desktop.png';
import partnersMobile from '../../../../assets/partners/partners-mobile.png';

export const PartnersSection = () => {
  const investorsSrc = useGetResponsivePropValue([investorsMobile, null, investorsDesktop]);
  const partnersSrc = useGetResponsivePropValue([partnersMobile, null, partnersDesktop]);

  return (
    <Container>
      <Box>
        <Typography
          variant="h2"
          color="#474747"
          textAlign="center"
        >
          {'Backed by the world\'s best investors'}
        </Typography>

        <LazyImage
          src={investorsSrc}
          alt="Logos for Wag! Investors"
          imageProps={{
            style: {
              objectFit: 'contain',
            },
          }}
          height={['160px', null, '80px']}
          width="100%"
        />
      </Box>

      <Box
        mt={[10, null, '190px']}
        mb="136px"
      >
        <Typography
          variant="h2"
          color="#474747"
          textAlign="center"
        >
          Supported by leading partners, including
        </Typography>

        <LazyImage
          src={partnersSrc}
          alt="Logos for Wag! Partners"
          imageProps={{
            style: {
              objectFit: 'contain',
            },
          }}
          height={['120px', null, '120px']}
          width="100%"
        />
      </Box>
    </Container>
  );
};
