// @flow
import React from 'react';
import Flex from '@waglabs/flex';
import { Icon } from '@waglabs/icon';
import Image from '@waglabs/image';

type Props = {
  bg: string,
  containerProps?: any,
  dataTestId?: string,
  height?: string,
  icon?: string,
  iconHeight?: string,
  iconProps?: any,
  iconWidth?: string,
  imageProps?: any,
  renderContent?: Function,
  src?: string,
  width?: string,
};

const Avatar = ({
  bg,
  containerProps,
  dataTestId,
  height,
  icon,
  iconHeight,
  iconProps,
  iconWidth,
  imageProps,
  renderContent,
  src,
  width,
}: Props) => (
  <Flex
    alignItems="center"
    bg={bg}
    borderRadius="50%"
    data-testid={dataTestId}
    height={height}
    justifyContent="center"
    m="0"
    overflow="hidden"
    p="0"
    width={width}
    {...containerProps}
  >
    {icon && !src && (
      <Icon
        backgroundPosition="center"
        backgroundSize="cover"
        height={iconHeight}
        name={icon}
        width={iconWidth}
        {...iconProps}
      />
    )}

    {src && (
      <Image
        backgroundPosition="center"
        backgroundSize="cover"
        height={height}
        src={src}
        width={width}
        borderRadius="50%"
        {...imageProps}
      />
    )}

    {renderContent && renderContent()}
  </Flex>
);

Avatar.defaultProps = {
  bg: 'primary',
  height: '90px',
  iconHeight: '90px',
  iconWidth: '90px',
  width: '90px',
};

export default Avatar;
