import React from 'react';

import { Box } from '@waglabs/box-next';
import { Carousel } from '@waglabs/carousel';
import { Flex } from '@waglabs/flex-next';
import { LazyImage } from '@waglabs/lazyload';

import { isEven } from '../../../../../../utils';

import caregiver1 from '../../../../../../assets/caregivers/caregiver1.png';
import caregiver2 from '../../../../../../assets/caregivers/caregiver2.png';
import caregiver3 from '../../../../../../assets/caregivers/caregiver3.png';
import caregiver4 from '../../../../../../assets/caregivers/caregiver4.png';
import caregiver5 from '../../../../../../assets/caregivers/caregiver5.png';

const images = [
  {
    src: caregiver1,
    key: 'Caregiver 1',
  },
  {
    src: caregiver2,
    key: 'Caregiver 2',
  },
  {
    src: caregiver3,
    key: 'Caregiver 3',
  },
  {
    src: caregiver4,
    key: 'Caregiver 4',
  },
  {
    src: caregiver5,
    key: 'Caregiver 5',
  },
];

export const CaregiversCarousel = () => (
  <Flex
    mt={[0, null, 9]}
    mb={[9, null, 15]}
  >
    <Carousel
      variant="slider"
    >
      {images.map((image, i) => (
        <Box
          key={image.key}
          pt={isEven(i) ? [3, null, 5] : 0}
          pr={2}
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay={300 * i}
        >
          <LazyImage
            src={image.src}
            alt="Pet Caregiver"
            height={['132px', null, '264px']}
            width={['180px', null, '364px']}
            imageProps={{
              style: {
                objectFit: 'contain',
              },
            }}
          />
        </Box>
      ))}
    </Carousel>
  </Flex>
);
