import React from 'react';

import styled from 'styled-components';

import { Box } from '@waglabs/box-next';
import { Container } from '@waglabs/container';
import { Flex } from '@waglabs/flex-next';
import { Typography } from '@waglabs/typography';
import { theme } from '@waglabs/base-theme';

const stats = [
  {
    stat: '12M+',
    description: 'Services Completed',
  },
  {
    stat: '1M+',
    description: 'Pet Parents',
  },
  {
    stat: '400K+',
    description: 'Pet Caregivers',
  },
  {
    stat: '16M+',
    description: 'Meals Donated',
  },
];

export const StatsSection = () => (
  <Container
    display="flex"
    flexDirection={['column', null, 'row']}
    my={[6, null, 15]}
  >
    <Flex
      flex="2"
      order={[2, null, 1]}
      pr={1}
    >
      <StyledBox
        alignSelf="flex-start"
        mt={[0, null, 15]}
        position={[null, null, 'sticky']}
        top={[null, null, '50%']}
      >
        <Typography
          fontSize={['22px', null, '32px']}
          lineHeight={['33px', null, '40px']}
        >
          {'It\'s all about convenience. We\'ve thought of everything, so you don\'t have to. From GPS-tracked walks and customized lockboxes, to set it and forget it booking in the Wag! app, Wag! makes being a Pet Parent easy. We\'re your one-stop shop for pet care, pet health and training.'}
        </Typography>
      </StyledBox>
    </Flex>
    <Flex
      flex="1"
      flexDirection="column"
      order={[1, null, 2]}
      px={[0, null, 2]}
      overflow="hidden"
    >
      {stats.map(({
        stat,
        description,
      }) => (
        <Box
          key={stat}
          mb={[5, null, '500px']}
        >
          <div
            data-aos="fade-left"
          >
            <Typography
              color="wagBlue"
              fontFamily="muliSemiBold"
              fontSize={['84px', null, '110px']}
              m={0}
              textAlign="center"
            >
              {stat}
            </Typography>
            <Typography
              color="#047B5B"
              fontFamily="muliBold"
              fontSize="22px"
              m={0}
              textAlign="center"
              textTransform="uppercase"
            >
              {description}
            </Typography>
          </div>
        </Box>
      ))}
    </Flex>
  </Container>
);

const StyledBox = styled(Box)`
  @media (min-width: ${theme.breakpoints[1]}) {
    transform: translateY(-50%);
  }
`;
