import React, { useEffect, useState } from 'react';

import { AnimationContainer } from '../../components/AnimationContainer';
import { ExecutiveSection } from './components/ExecutiveSection';
import { Footer } from './components/Footer';
import { HeroSection } from './components/HeroSection';
import { HowItWorksSection } from './components/HowItWorksSection';
import { LetterSection } from './components/LetterSection';
import { PartnersSection } from './components/PartnersSection';
import { PetCaregiversSection } from './components/PetCaregiversSection';
import { ReviewsSection } from './components/ReviewsSection';
import { ServicesSection } from './components/ServicesSection';
import { StatsSection } from './components/StatsSection';
import { TimelineSection } from './components/TimelineSection';

import { scrollToViewById } from '../../utils/scrollTo';

export const HomePageContainer = () => {
  const [homeLoaded, setHomeLoaded] = useState(false);

  useEffect(() => {
    if (homeLoaded === true) return;
    if (window.location.hash.length > 1) {
      setTimeout(() => (scrollToViewById(document.location.hash.split('#')[1])), 1000);
    }
    setHomeLoaded(true);
  }, [homeLoaded]);

  return (
    <AnimationContainer>
      <HeroSection />
      <StatsSection />
      <TimelineSection />
      <ServicesSection />
      <ReviewsSection />
      <HowItWorksSection />
      <PetCaregiversSection />
      <ExecutiveSection />
      <LetterSection />
      <PartnersSection />
      <Footer />
    </AnimationContainer>
  );
};
