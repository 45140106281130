import React, {
  useState,
} from 'react';

import { Box } from '@waglabs/box-next';
import { Container } from '@waglabs/container';
import { Flex } from '@waglabs/flex-next';
import { LazyImage } from '@waglabs/lazyload';
import { Image } from '@waglabs/image-next';
import { Typography } from '@waglabs/typography';
import Link from '@waglabs/link';

import { ServiceUrls } from '../../../../constants';

import image20151x from '../../../../assets/timeline/2015-1x.png';
import image20152x from '../../../../assets/timeline/2015-2x.png';
import image20153x from '../../../../assets/timeline/2015-3x.png';
import image20161x from '../../../../assets/timeline/2016-1x.png';
import image20162x from '../../../../assets/timeline/2016-2x.png';
import image20163x from '../../../../assets/timeline/2016-3x.png';
import image2017 from '../../../../assets/timeline/2017.svg';
import image20181x from '../../../../assets/timeline/2018-1x.png';
import image20182x from '../../../../assets/timeline/2018-2x.png';
import image20183x from '../../../../assets/timeline/2018-3x.png';
import image2019 from '../../../../assets/timeline/2019.svg';
import image20201x from '../../../../assets/timeline/2020-1x.png';
import image20202x from '../../../../assets/timeline/2020-2x.png';
import image20203x from '../../../../assets/timeline/2020-3x.png';
import image20211x from '../../../../assets/timeline/2021-1x.png';
import image20212x from '../../../../assets/timeline/2021-2x.png';
import image20213x from '../../../../assets/timeline/2021-3x.png';
import image20221x from '../../../../assets/timeline/2022-1x.png';
import image20222x from '../../../../assets/timeline/2022-2x.png';
import image20223x from '../../../../assets/timeline/2022-3x.png';
import image20231x from '../../../../assets/timeline/2023-1x.png';
import image20232x from '../../../../assets/timeline/2023-2x.png';
import image20233x from '../../../../assets/timeline/2023-3x.png';
import line from '../../../../assets/timeline/line.svg';

const YEAR_2015 = '2015';
const YEAR_2016 = '2016';
const YEAR_2017 = '2017';
const YEAR_2018 = '2018';
const YEAR_2019 = '2019';
const YEAR_2020 = '2020';
const YEAR_2021 = '2021';
const YEAR_2022 = '2022';
const YEAR_2023 = '2023';

const timelineData = {
  [YEAR_2015]: {
    alt: 'Wag! dog walk',
    srcset: `${image20151x} 1x, ${image20152x} 2x, ${image20153x} 3x`,
    src: image20152x,
    year: YEAR_2015,
    shortYear: '15',
    description: 'Wag! invented on-demand dog walking.',
    styleProps: {
      mt: ['8px', null, 0],
    },
  },
  [YEAR_2016]: {
    alt: 'Pet Parent and Dog',
    srcset: `${image20161x} 1x, ${image20162x} 2x, ${image20163x} 3x`,
    src: image20162x,
    year: YEAR_2016,
    shortYear: '16',
    description: 'Thousands of Pet Parents in Los Angeles fell in love with the Wag! App.',
    styleProps: {
      mt: ['8px', null, '4px'],
    },
  },
  [YEAR_2017]: {
    alt: 'Wag service locations in the United States',
    src: image2017,
    year: YEAR_2017,
    shortYear: '17',
    description: 'Wag! expands to 25 major cities across the United States.',
    styleProps: {
      mt: ['12px', null, '8px'],
    },
  },
  [YEAR_2018]: {
    alt: 'Wag! Drop-In, Sitting and Boarding',
    srcset: `${image20181x} 1x, ${image20182x} 2x, ${image20183x} 3x`,
    src: image20182x,
    year: YEAR_2018,
    shortYear: '18',
    description: 'Wag! expands into Drop-Ins, Sitting, and Boarding services.',
    styleProps: {
      mt: ['8px', null, '6px'],
    },
  },
  [YEAR_2019]: {
    alt: 'Wag! expansion across the United States',
    src: image2019,
    year: YEAR_2019,
    shortYear: '19',
    description: 'The platform expands to 4,786 cities across 50 U.S. states.',
    styleProps: {
      mt: ['8px', null, '6px'],
    },
  },
  [YEAR_2020]: {
    alt: 'Wag! vet care, training, wellness, and cat services',
    srcset: `${image20201x} 1x, ${image20202x} 2x, ${image20203x} 3x`,
    src: image20202x,
    year: YEAR_2020,
    shortYear: '20',
    description: 'Wag! becomes the #1 on-demand Pet Parent partner across vet care, training, wellness, and cat services.',
    styleProps: {
      mt: ['8px', null, '6px'],
    },
  },
  [YEAR_2021]: {
    alt: 'Wag! pet caregivers and parents',
    srcset: `${image20211x} 1x, ${image20212x} 2x, ${image20213x} 3x`,
    src: image20212x,
    year: YEAR_2021,
    shortYear: '21',
    description: 'More than 5,000,000 Pet Parents and 350,000 Pet Caregivers count on Wag! for pet care and pet health advice. Wag! also acquires Petted.com – a pet insurance comparison tool.',
    styleProps: {
      mt: ['10px', null, '8px'],
    },
  },
  [YEAR_2022]: {
    alt: 'Wag! vet care, training, wellness, and cat services',
    srcset: `${image20221x} 1x, ${image20222x} 2x, ${image20223x} 3x`,
    src: image20222x,
    year: YEAR_2022,
    shortYear: '22',
    descriptionBlock: (
      <Typography>
        Wag! serves Pet Parents in more than 5,300 cities across the U.S.
        with over 450,000 caregivers approved on the platform and
        acquires
        <Link
          href={ServiceUrls.Furmacy}
          color="wagGreen"
          target="_blank"
        >
          &nbsp;Furmacy.com&nbsp;
        </Link>
        – a full-service pet pharmacy platform.
      </Typography>
    ),
    styleProps: {
      mt: ['8px', null, '6px'],
    },
  },
  [YEAR_2023]: {
    alt: 'Wag! enters the Pet Food & Treats market',
    srcset: `${image20231x} 1x, ${image20232x} 2x, ${image20233x} 3x`,
    src: image20232x,
    year: YEAR_2023,
    shortYear: '23+',
    descriptionBlock: (
      <Typography>
        Wag! enters the Pet Food & Treats market through acquisition of
        <Link
          href={ServiceUrls.DogFoodAdvisor}
          color="wagGreen"
          target="_blank"
        >
          &nbsp;DogFoodAdvisor.com&nbsp;
        </Link>
        - one of the most visited and trusted pet food marketplaces.
      </Typography>
    ),
    styleProps: {
      mt: ['8px', null, '6px'],
    },
  },
};

type TimelineSectionProps = {
  startYear?: string;
};

export const TimelineSection = ({
  startYear = YEAR_2015,
}: TimelineSectionProps) => {
  let timelineYear = startYear;
  if (!timelineData[startYear]) {
    // If for some reason a year is passed in as props that doesn't exist in the timelineData
    // object, reassign it to YEAR_2015
    timelineYear = YEAR_2015;
  }

  const [activeYear, setActiveYear] = useState(timelineYear);
  const isActiveYear = (year: string) => activeYear === year;

  return (
    <Container>
      <Flex
        justifyContent="space-between"
        mb={[3, null, 0]}
        position="relative"
      >
        <Image
          alt="Timeline line"
          src={line}
          position="absolute"
          imageProps={{
            style: {
              objectFit: 'contain',
            },
          }}
          width={1}
          top={3}
          zIndex={0}
        />
        {Object.keys(timelineData).map((year) => (
          <Flex
            key={year}
            onMouseEnter={() => setActiveYear(year)}
            alignItems="center"
            flex={1}
            flexDirection="column"
            zIndex={1}
            {...timelineData[year].styleProps}
          >
            <Box
              bg={isActiveYear(year) ? 'wagGreen' : 'lightGray'}
              border={isActiveYear(year) ? ['8px solid rgba(255, 255, 255, 0.85)', null, '12px solid rgba(255, 255, 255, 0.85)'] : ['8px solid white', null, '12px solid white']}
              borderRadius="30px"
              width={['32px', null, '48px']}
              height={['32px', null, '48px']}
            />

            {isActiveYear(year) ? (
              <>
                <Box
                  borderColor="wagGreen"
                  borderStyle="solid"
                  borderWidth={[0, null, '1px']}
                  height={[0, null, '28px']}
                  mt={[0, null, 1]}
                  width={[0, null, '2px']}
                />
                <Typography
                  color="wagBlue"
                  fontFamily="muliBold"
                  fontSize={['24px', null, '48px']}
                  m={0}
                  mt={[2, null, 0]}
                >
                  {`${timelineData[year].year}`}
                </Typography>
              </>
            ) : (
              <Typography
                color="wagGray"
                fontFamily="muliBold"
                fontSize={['20px', null, '26px']}
                m={0}
                mt={[0, null, 1]}
              >
                {`'${timelineData[year].shortYear}`}
              </Typography>
            )}
          </Flex>
        ))}
      </Flex>

      <Flex
        justifyContent="center"
        mb={[13, null, 0]}
      >
        <Flex
          alignItems="center"
          flexDirection={['column', null, 'row']}
          width={[1, null, '75%']}
        >
          <Box
            flex={1}
            mr={[0, null, 5]}
          >
            <LazyImage
              alt={timelineData[activeYear].alt}
              srcSet={timelineData[activeYear].srcset ? timelineData[activeYear].srcset : null}
              src={timelineData[activeYear].src}
              imageProps={{
                style: {
                  objectFit: 'contain',
                },
              }}
              height={['256px', null, '100%']}
              width={['316px', null, '100%']}
            />
          </Box>
          <Box
            flex={1}
          >
            {timelineData[activeYear]?.descriptionBlock
              ? timelineData[activeYear]?.descriptionBlock
              : (
                <Typography>
                  {timelineData[activeYear].description}
                </Typography>
              )}
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
};
