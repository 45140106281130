import React from 'react';

import { Container } from '@waglabs/container';
import { VideoLightbox } from '@waglabs/video-lightbox';
import { Typography } from '@waglabs/typography';

import thumbnail1x from '../../../../assets/how-it-works/how-it-works-1x.jpg';
import thumbnail2x from '../../../../assets/how-it-works/how-it-works-2x.png';
import thumbnail3x from '../../../../assets/how-it-works/how-it-works-3x.png';
import { VideoUrls } from '../../../../constants';

export const HowItWorksSection = () => (
  <Container>
    <Typography
      variant="h2"
      color="#474747"
      textAlign="center"
    >
      How it works
    </Typography>
    <VideoLightbox
      thumbnailSet={`${thumbnail1x} 1x, ${thumbnail2x} 2x, ${thumbnail3x} 3x`}
      thumbnail={thumbnail2x}
      thumbnailProps={{
        height: ['236px', '440px'],
      }}
      src={VideoUrls.ProductOverview}
    />
  </Container>
);
