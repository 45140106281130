import React from 'react';

import Head from 'next/head';

import { HomePageContainer } from '../containers/HomePageContainer';
import { NewRelicBrowserScript } from '../components/NewRelicBrowserScript';
import { GoogleTagManager } from '../components/GoogleTagManager';

import {
  ENV_NEWRELIC_BROWSER_ACCOUNT_ID,
  ENV_NEWRELIC_BROWSER_AGENT_ID,
  ENV_NEWRELIC_BROWSER_APPLICATION_ID,
  ENV_NEWRELIC_BROWSER_LICENSE_KEY,
  ENV_NEWRELIC_BROWSER_TRUST_KEY,
  GOOGLE_TAG_MANAGER_ID,
} from '../constants/envs';

const Home = () => (
  <>
    <Head>
      <title>Wag! – Any Day, Any Time Pet Care</title>
      <meta
        name="description"
        content="We believe that being busy shouldn’t stop you from owning or taking care of your pet. Wag! exists to make pet ownership possible, and bring joy to pets and those who love them."
      />
      <link
        rel="canonical"
        href={process.env.NEXT_PUBLIC_SITE_URL}
      />
      <meta
        name="robots"
        content="index, follow"
      />
    </Head>
    <NewRelicBrowserScript
      accountId={ENV_NEWRELIC_BROWSER_ACCOUNT_ID}
      agentId={ENV_NEWRELIC_BROWSER_AGENT_ID}
      applicationId={ENV_NEWRELIC_BROWSER_APPLICATION_ID}
      licenseKey={ENV_NEWRELIC_BROWSER_LICENSE_KEY}
      trustKey={ENV_NEWRELIC_BROWSER_TRUST_KEY}
    />
    <GoogleTagManager
      containerId={GOOGLE_TAG_MANAGER_ID}
    />
    <HomePageContainer />
  </>
);

export default Home;
