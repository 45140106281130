import React from 'react';

import { createGlobalStyle } from 'styled-components';

import {
  StarRatingsIcon,
  StarRatingsIconProps,
} from '../../../../../components/Icons/StarRatingsIcon';

export const StarAnimationStyle = createGlobalStyle`
  .star {
    animation-name: star;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  #star-1 {
    animation-duration: 0.5s;
    animation-delay: 0.5s;
  }

  #star-2 {
    animation-duration: 0.7s;
    animation-delay: 1.5s;
  }

  #star-3 {
    animation-duration: 0.9s;
    animation-delay: 2.5s;
  }

  #star-4 {
    animation-duration: 1.1s;
    animation-delay: 3.5s;
  }

  #star-5 {
    animation-duration: 1.3s;
    animation-delay: 4.5s;
  }

  @keyframes star {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.5;
    }
    70% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
`;

export type StarRatingsProps = StarRatingsIconProps;
export const StarRatings = (props: StarRatingsIconProps) => (
  <>
    <StarAnimationStyle />
    <StarRatingsIcon
      width="360px"
      height="60px"
      m="auto"
      {...props}
    />
  </>
);
