import React from 'react';

import { Box } from '@waglabs/box-next';
import { Container } from '@waglabs/container';
import { Image } from '@waglabs/image-next';
import { Typography } from '@waglabs/typography';
import Span from '@waglabs/span';
import { useGetResponsivePropValue } from '@waglabs/use-get-responsive-prop-value';

import lineDesktop from '../../../../assets/wave-line-desktop.svg';
import lineMobile from '../../../../assets/wave-line-mobile.svg';
import signature from '../../../../assets/ceo-signature.jpg';

export const LetterSection = () => {
  const lineSrc = useGetResponsivePropValue([lineMobile, null, lineDesktop]);

  return (
    <Container>
      <Image
        src={lineSrc}
        width={1}
      />

      <Box
        maxWidth="900px"
        margin="auto"
        py={7}
      >
        <Typography
          variant="h3"
          color="#474747"
          fontSize={['20px', null, '28px']}
        >
          Letter from the CEO
        </Typography>
        <Typography
          fontStyle="italic"
        >
          To the community,
        </Typography>
        <Typography
          fontStyle="italic"
        >
          The most important thing you can know is that I plan to dedicate my life to building a
          future in which every pet has access to the best quality care. This future will be
          built by a passionate community of pet lovers who want to spend their time creating joy
          for pets and those who love them.
        </Typography>
        <Typography
          fontStyle="italic"
        >
          Wag! is the technology platform for building that future and we are just getting started.
        </Typography>

        <Image
          src={signature}
          mt={2}
          width="166px"
        />
        <Typography
          fontStyle="italic"
          mt={0}
        >
          <Span
            color="#474747"
            fontStyle="italic"
          >
            Garrett Smallwood
          </Span>
          <br />
          Chief Executive Officer and Chairman
          <br />
          Wag! Group Co.
        </Typography>
      </Box>

      <Image
        src={lineSrc}
        width={1}
      />
    </Container>
  );
};
