import React from 'react';

import NextLink from 'next/link';

import { Container } from '@waglabs/container';
import { Box } from '@waglabs/box-next';
import { Flex } from '@waglabs/flex-next';
import { WagLogoIcon } from '@waglabs/icons';
import { Image } from '@waglabs/image-next';
import Link from '@waglabs/link';
import { Typography } from '@waglabs/typography';

import { ENV_URL_HOME, Urls } from '../../../../constants';
import pawImg from '../../../../assets/paw-faded.svg';
import dottedLineDesktop from '../../../../assets/dotted-line-desktop.svg';
import dottedLineMobile from '../../../../assets/dotted-line-mobile.svg';
import photoBannerDesktop from '../../../../assets/photo-banner-desktop.png';

export const HeroSection = () => (
  <>
    <Flex
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box
        px={[2, null, 8]}
        py={4}
      >
        <Link
          href={ENV_URL_HOME}
        >
          <WagLogoIcon
            height={['24px', null, '48px']}
            width={['48px', null, '100px']}
          />
        </Link>
      </Box>
      <Box
        px={[2, null, 8]}
        py={['17px', '17px', 4]}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
        >
          <NextLink
            href={Urls.Investors}
            passHref
          >
            <Link
              href={Urls.Investors}
              color="wagGreen"
            >
              Investor Relations
            </Link>
          </NextLink>
        </Typography>
      </Box>
    </Flex>

    <Container>
      <Flex
        backgroundImage={pawImg}
        flexDirection={['column', null, 'row']}
        alignItems="center"
      >
        <Typography
          variant="h1"
          color="wagBlue"
          fontFamily="muliBold"
          fontSize={['60px', null, '85px', '120px']}
          m={0}
        >
          The button on the phone for the paw
        </Typography>

        <Flex
          alignSelf="center"
          my={[2, null, 0]}
        >
          <div
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <Image
              src={photoBannerDesktop}
              width={[1, '300px', '624px']}
              height={['240px', null, '472px']}
              alt="Wag! Photo Banner"
            />
          </div>
        </Flex>
      </Flex>

      <Box
        mt={3}
        width={1}
      >
        <Box
          backgroundImage={[`url(${dottedLineMobile})`, `url(${dottedLineDesktop})`]}
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          height="20px"
        />

        <Typography
          color="#474747"
          fontFamily="muliBold"
          fontSize={['30px', null, '48px']}
          my={[4, null, 8]}
          textAlign={['left', null, 'center']}
        >
          We believe that being busy shouldn’t stop you from owning or taking care of your pet.
        </Typography>

        <Box
          backgroundImage={[`url(${dottedLineMobile})`, `url(${dottedLineDesktop})`]}
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          height="20px"
        />
      </Box>
    </Container>
  </>
);
