// TODO: Move to wag-react when Next.js supports the ability for npm packages to import CSS files
// See: https://nextjs.org/docs/messages/css-npm
import React, {
  ReactNode,
  useEffect,
} from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

export type AnimationContainerProps = {
  children: ReactNode,
  animationProps?: {
    [key: string]: any,
  },
};

export const AnimationContainer = ({
  children,
  animationProps,
}: AnimationContainerProps) => {
  useEffect(() => {
    if (AOS.refresh() === undefined) {
      AOS.init(animationProps);
    }
  }, [animationProps]);

  return (
    <>
      {children}
    </>
  );
};
