import React from 'react';

import { Container } from '@waglabs/container';
import { Flex } from '@waglabs/flex-next';
import {
  CalendarClockIcon,
  MapLocationPinIcon,
  SecureIcon,
} from '@waglabs/icons';
import { Image } from '@waglabs/image-next';
import { Typography } from '@waglabs/typography';
import { useGetResponsivePropValue } from '@waglabs/use-get-responsive-prop-value';

import { CaregiversCarousel } from './components/CaregiversCarousel';
import lineDesktop from '../../../../assets/wave-line-desktop.svg';
import lineMobile from '../../../../assets/wave-line-mobile.svg';

const productFeatures = [
  {
    IconComponent: CalendarClockIcon,
    title: 'Flexibility',
    description: 'Make money on your own time. Set your own schedule. Enjoy the flexibility of choosing how and when you want to work—grab a last minute ASAP walk or plan out walks weeks in advance!',
  },
  {
    IconComponent: MapLocationPinIcon,
    title: 'Technology',
    description: 'Wag! is a fast, friendly, and convenient platform that offers instant pay, real-time GPS tracking, automated report cards, and live chat with clients.',
  },
  {
    IconComponent: SecureIcon,
    title: 'Safety',
    description: 'Our dedicated customer support team sticks by you 7 days a week, and services booked on the Wag! platform may be protected with up to $1M property damage protection.',
  },
];

export const PetCaregiversSection = () => {
  const lineSrc = useGetResponsivePropValue([lineMobile, null, lineDesktop]);

  return (
    <>
      <Container>
        <Image
          src={lineSrc}
          mt={[8, null, 15]}
          width={1}
          alt="Wavy line"
        />

        <Typography
          variant="h2"
          color="#474747"
          mb={[6, null, 12]}
          mt={[4, null, 9]}
          textAlign="center"
        >
          {'Pet Caregivers love Wag! and it\'s obvious why'}
        </Typography>

        <Flex
          flexDirection={['column', null, 'row']}
          mt={[4, null, 0]}
        >
          {productFeatures.map(({
            IconComponent,
            title,
            description,
          }) => (
            <Flex
              flex={1}
              flexDirection="column"
              mb={[6, null, 0]}
              mr={3}
              key={title}
            >
              <Flex
                alignItems="center"
                flexDirection={['column', null, 'row']}
              >
                <IconComponent
                  height="48px"
                  width="48px"
                  mr={[0, null, 2]}
                />
                <Typography
                  color="wagBlue"
                  fontFamily="muliBold"
                >
                  {title}
                </Typography>
              </Flex>
              <Typography
                textAlign={['center', null, 'left']}
              >
                {description}
              </Typography>
            </Flex>
          ))}
        </Flex>
      </Container>

      <CaregiversCarousel />
    </>
  );
};
