import styled from 'styled-components';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
  variant,
} from 'styled-system';

const textStyles = variant({
  key: 'texts',
});

const styleProps = compose(
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography
)

const Span = styled.span`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /**
   * ! Variant helpers should always be on top to be able to override some style presets
   */
  ${textStyles}
  
  ${styleProps}

  box-sizing: ${props => props.boxSizing || 'content-box'};
  text-indent: ${props => props.textIndent || '0'};
  text-decoration: ${props => props.textDecoration || 'none'};
  text-transform: ${props => (props.textTransform || 'none')};
  font-style: ${props => props.fontStyle || 'normal'};
  white-space: ${props => props.whiteSpace || 'normal'};
  opacity: ${props => props.opacity || '1'};
  transition: ${props => props.transition || 'none'};
  visibility: ${props => props.visibility || 'visible'};

  &:hover {
    cursor: ${props => (props.hover ? 'pointer' : 'auto')};
  }
`;

export default Span;
