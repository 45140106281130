import React from 'react';

import 'swiper/swiper-bundle.css';
import LazyLoad from 'react-lazyload';

import { Box } from '@waglabs/box-next';
import { ReviewCarousel } from '@waglabs/review-carousel';
import { Container } from '@waglabs/container';
import { Flex } from '@waglabs/flex-next';
import { LazyImage } from '@waglabs/lazyload';
import { Typography } from '@waglabs/typography';
import { useGetResponsivePropValue } from '@waglabs/use-get-responsive-prop-value';

import { StarRatings } from './components/StarRatings';

import wave from '../../../../assets/wave.svg';
import waveMobile from '../../../../assets/wave-mobile.svg';
import dog1 from '../../../../assets/dogs/dog1.png';
import dog2 from '../../../../assets/dogs/dog2.png';
import dog3 from '../../../../assets/dogs/dog3.png';
import dog4 from '../../../../assets/dogs/dog4.png';
import dog5 from '../../../../assets/dogs/dog5.png';
import dog6 from '../../../../assets/dogs/dog6.png';

const reviews = [
  {
    src: dog1,
    author: 'Ken C.',
    body: 'I typically walk my dogs, but couldn’t for a couple of days. Found Wag!, downloaded the app and had someone at the house in just a few hours. Great experience!',
    title: 'Easy to get started',
  },
  {
    src: dog2,
    author: 'Meghan S.',
    body: 'Wag! is an exceptional dog service. They have the most excellent people that are highly trained and experienced in areas with animals right from the start. I recommend everyone give it a try you will be glad you did and so will your pup.',
    title: 'Highly trained walkers',
  },
  {
    src: dog3,
    author: 'Sarah P.',
    body: 'Love being able to see where the dogs were walked and pictures of them while with their caregiver!',
    title: 'GPS Tracking',
  },
  {
    src: dog4,
    author: 'Milton F.',
    body: 'Wag is the best service for pet owners (specifically dogs in my case) hands down. I have a Rottweiler that needed to be overnight boarded 3 weeks in a row due to family obligations. I couldn\'t find a local kennel I trusted to provide the care needed for my baby boy without having to pay an arm and a leg. After finding Wag, all of my worries went away. Truly a great service!',
    title: 'Trusted sitting and boarding',
  },
  {
    src: dog5,
    author: 'Rita A.',
    body: 'So happy I discovered Wag! I go out of town on the weekends sometimes so my cat is all alone, and Wag! sends someone in at an affordable rate to feed him and care for him. I love it!',
    title: 'Not just for dogs!',
  },
  {
    src: dog6,
    author: 'Deborah G.',
    body: 'I have used Wag! for the past few years and have always been pleased. The walkers are personable and always on time. They all seem like they love dogs! I have recommended Wag! to many people!',
    title: 'Cultivating loyal customers',
  },
];

export const ReviewsSection = () => {
  const waveSrc = useGetResponsivePropValue([waveMobile, null, wave]);

  return (
    <>
      <Box
        bg="#F2F2F2"
        pt={[8, null, '160px']}
      >
        <Container>
          <Box
            display={['block', null, 'flex']}
            justifyContent="center"
          >
            <Flex
              flexDirection={['column', null, 'row']}
              maxWidth="1100px"
            >
              <Box
                flex={1}
                textAlign="center"
              >
                <Typography
                  color="#474747"
                  fontFamily="muliSemiBold"
                  fontSize={['90px', null, '130px']}
                  m={0}
                >
                  4.8/5
                </Typography>
                <LazyLoad
                  height="60px"
                >
                  <StarRatings />
                </LazyLoad>
                <Typography
                  color="wagBlue"
                  fontFamily="muliSemiBold"
                  fontSize={['24px', null, '30px']}
                  mt={3}
                  textTransform="uppercase"
                >
                  42k+ Reviews
                </Typography>
              </Box>
              <Box
                flex={1}
                overflow="hidden"
              >
                <ReviewCarousel
                  reviews={reviews}
                />
              </Box>
            </Flex>
          </Box>
        </Container>
      </Box>
      <LazyImage
        src={waveSrc}
        height={['52px', null, '167px']}
        mt="-1px"
        width={1}
      />
    </>
  );
};
