import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

/**
 * This icon component have an `id` and `class` on
 * each group (`g`) in order to be selected and animated
 *
 * We can minify the SVG, however, that might
 * reduce the readability when debugging with animations
 */
export type StarRatingsIconProps = SvgIconProps;
export const StarRatingsIcon = (props: StarRatingsIconProps) => (
  <>
    <SvgIcon
      viewBox="0 0 362 63"
      {...props}
    >
      <defs>
        <path
          d="M1.68640272,24.818499 L1.68640272,24.9479868 L13.3464213,38.8247649 L7.41759827,55.8092507 C5.88049602,60.2334179 8.12027359,62.1541539 12.4241599,60.1255114 L30.8254698,51.1261079 L49.204821,60.1255114 C53.5965417,62.2836417 55.7924021,60.2981618 54.2113826,55.8092507 L48.3045183,38.8247649 L59.9425782,24.9479868 L59.9425782,24.818499 C63.0826585,21.2575839 61.7212251,18.3441079 56.9342495,18.3441079 L41.5632269,18.3441079 L34.2510119,3.23719552 C32.2747376,-1.07906517 29.0029057,-1.07906517 27.0266313,3.23719552 L19.7144163,18.3441079 L4.34339376,18.3441079 C-0.00440975931,18.3441079 -1.4536776,21.2575839 1.68640272,24.818499 Z"
          id="path-1"
        />
        <filter
          colorInterpolationFilters="auto"
          id="filter-3"
        >
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        id="R3"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="photos"
          transform="translate(-1784.000000, -68.000000)"
        >
          <g
            id="star-rating"
            transform="translate(1784.000000, 68.000000)"
          >
            <g
              id="star-1"
              className="star-1 star"
              transform="translate(0.000000, 1.000000)"
              fill="#FABC44"
              fillRule="nonzero"
            >
              <g id="rating">
                <path
                  d="M1.68640272,24.818499 L1.68640272,24.9479868 L13.3464213,38.8247649 L7.41759827,55.8092507 C5.88049602,60.2334179 8.12027359,62.1541539 12.4241599,60.1255114 L30.8254698,51.1261079 L49.204821,60.1255114 C53.5965417,62.2836417 55.7924021,60.2981618 54.2113826,55.8092507 L48.3045183,38.8247649 L59.9425782,24.9479868 L59.9425782,24.818499 C63.0826585,21.2575839 61.7212251,18.3441079 56.9342495,18.3441079 L41.5632269,18.3441079 L34.2510119,3.23719552 C32.2747376,-1.07906517 29.0029057,-1.07906517 27.0266313,3.23719552 L19.7144163,18.3441079 L4.34339376,18.3441079 C-0.00440975931,18.3441079 -1.4536776,21.2575839 1.68640272,24.818499 Z"
                  id="ic_report_card_star_selected"
                />
              </g>
            </g>
            <g
              id="star-2"
              className="star-2 star"
              transform="translate(74.841463, 1.000000)"
              fill="#FABC44"
              fillRule="nonzero"
            >
              <g id="rating">
                <path
                  d="M1.68640272,24.818499 L1.68640272,24.9479868 L13.3464213,38.8247649 L7.41759827,55.8092507 C5.88049602,60.2334179 8.12027359,62.1541539 12.4241599,60.1255114 L30.8254698,51.1261079 L49.204821,60.1255114 C53.5965417,62.2836417 55.7924021,60.2981618 54.2113826,55.8092507 L48.3045183,38.8247649 L59.9425782,24.9479868 L59.9425782,24.818499 C63.0826585,21.2575839 61.7212251,18.3441079 56.9342495,18.3441079 L41.5632269,18.3441079 L34.2510119,3.23719552 C32.2747376,-1.07906517 29.0029057,-1.07906517 27.0266313,3.23719552 L19.7144163,18.3441079 L4.34339376,18.3441079 C-0.00440975931,18.3441079 -1.4536776,21.2575839 1.68640272,24.818499 Z"
                  id="ic_report_card_star_selected"
                />
              </g>
            </g>
            <g
              id="star-3"
              className="star-3 star"
              transform="translate(149.682927, 1.000000)"
              fill="#FABC44"
              fillRule="nonzero"
            >
              <g id="rating">
                <path
                  d="M1.68640272,24.818499 L1.68640272,24.9479868 L13.3464213,38.8247649 L7.41759827,55.8092507 C5.88049602,60.2334179 8.12027359,62.1541539 12.4241599,60.1255114 L30.8254698,51.1261079 L49.204821,60.1255114 C53.5965417,62.2836417 55.7924021,60.2981618 54.2113826,55.8092507 L48.3045183,38.8247649 L59.9425782,24.9479868 L59.9425782,24.818499 C63.0826585,21.2575839 61.7212251,18.3441079 56.9342495,18.3441079 L41.5632269,18.3441079 L34.2510119,3.23719552 C32.2747376,-1.07906517 29.0029057,-1.07906517 27.0266313,3.23719552 L19.7144163,18.3441079 L4.34339376,18.3441079 C-0.00440975931,18.3441079 -1.4536776,21.2575839 1.68640272,24.818499 Z"
                  id="ic_report_card_star_selected"
                />
              </g>
            </g>
            <g
              id="star-4"
              className="star-4 star"
              transform="translate(224.524390, 1.000000)"
              fill="#FABC44"
              fillRule="nonzero"
            >
              <g id="rating">
                <path
                  d="M1.68640272,24.818499 L1.68640272,24.9479868 L13.3464213,38.8247649 L7.41759827,55.8092507 C5.88049602,60.2334179 8.12027359,62.1541539 12.4241599,60.1255114 L30.8254698,51.1261079 L49.204821,60.1255114 C53.5965417,62.2836417 55.7924021,60.2981618 54.2113826,55.8092507 L48.3045183,38.8247649 L59.9425782,24.9479868 L59.9425782,24.818499 C63.0826585,21.2575839 61.7212251,18.3441079 56.9342495,18.3441079 L41.5632269,18.3441079 L34.2510119,3.23719552 C32.2747376,-1.07906517 29.0029057,-1.07906517 27.0266313,3.23719552 L19.7144163,18.3441079 L4.34339376,18.3441079 C-0.00440975931,18.3441079 -1.4536776,21.2575839 1.68640272,24.818499 Z"
                  id="ic_report_card_star_selected"
                />
              </g>
            </g>
            <g
              id="star-5"
              className="star-5 star"
              transform="translate(299.365854, 1.000000)"
            >
              <g id="rating">
                <mask
                  id="star-mask-2"
                  fill="white"
                >
                  <use xlinkHref="#path-1" />
                </mask>
                <g
                  id="ic_report_card_star_selected"
                  fillRule="nonzero"
                />
                <path
                  d="M61.6341463,3.37041871e-12 L61.6351951,17.5755129 C61.0958882,17.43319 60.4813246,17.3226611 59.8091604,17.240658 C58.9560381,16.3010382 56.5976428,15.3264713 53.9426397,14.3568149 L53.9426397,14.3568149 L53.3882105,14.1558177 L53.3882105,14.1558177 L53.1085556,14.0553965 L52.5458623,13.8547585 C52.2636872,13.7545175 51.9805688,13.6544034 51.6978545,13.5544605 L51.6978545,13.5544605 L51.133364,13.3548162 L50.5723462,13.1557076 C47.6868509,12.1285001 45.0363221,11.1259063 44.1073653,10.1969495 C43.5265715,9.61615571 42.8608796,9.169826 42.1481744,8.85881002 C42.8524827,7.10078236 42.519482,5.04965984 41.1333113,3.66348916 C39.2590524,1.78923022 36.1691227,1.84038576 34.2317602,3.77774825 C32.2943977,5.71511075 32.2432422,8.80504043 34.1175011,10.6792994 L34.1175011,10.6792994 L34.2201376,10.7775542 C31.9230435,13.3886808 31.7233895,16.75416 34.4606262,19.8436885 C35.4462975,20.8662977 37.1535309,21.4971904 39.2352184,21.8289362 C38.5023968,23.960056 39.0664178,26.5179334 40.8798625,28.331378 C43.247026,30.6985415 46.8826395,30.9367935 49.1810999,28.9398955 C49.1577615,30.207509 49.6656496,31.5311985 50.6867723,32.5523212 C52.5610312,34.4265801 55.4546431,34.5717424 57.1498353,32.8765502 C58.242484,31.7839014 60.5993998,28.6476529 61.6352114,26.0544971 L61.6341463,61 L2.90237817e-12,61 L2.90237817e-12,3.37041871e-12 L61.6341463,3.37041871e-12 Z"
                  id="Combined-Shape"
                  fill="#FABC44"
                  mask="url(#star-mask-2)"
                />
                <g
                  filter="url(#filter-3)"
                  id="Group"
                >
                  <g mask="url(#star-mask-2)">
                    <g transform="translate(46.290781, 17.727166) rotate(-45.000000) translate(-46.290781, -17.727166) translate(34.966374, -0.663577)" />
                  </g>
                </g>
              </g>
              <ellipse
                id="Oval"
                fill="#FABC44"
                cx="42.8014905"
                cy="11.8611111"
                rx="1.71205962"
                ry="1.69444444"
              />
              <ellipse
                id="Oval"
                fill="#FABC44"
                cx="50.5057588"
                cy="16.0972222"
                rx="2.56808943"
                ry="2.54166667"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  </>
);
