import React from 'react';

import { Box } from '@waglabs/box-next';
import { Flex } from '@waglabs/flex-next';
import {
  ServiceBoardingCircleIcon,
  ServiceDropInCircleIcon,
  ServiceHealthCircleIcon,
  ServiceOnDemandWalkCircleIcon,
  ServicePremiumIcon,
  ServiceRecurringWalkCircleIcon,
  ServiceSittingCircleIcon,
  ServiceTrainingCircleIcon,
} from '@waglabs/icons';
import { LazyImage } from '@waglabs/lazyload';
import Link from '@waglabs/link';
import { Typography } from '@waglabs/typography';

import { ServiceUrls } from '../../../../constants';
import { isEven } from '../../../../utils';

import boarding2xImage from '../../../../assets/services/service-boarding-desktop-2x.jpg';
import boarding3xImage from '../../../../assets/services/service-boarding-desktop-3x.jpg';
import dropIn2xImage from '../../../../assets/services/service-drop-ins-desktop-2x.jpg';
import dropIn3xImage from '../../../../assets/services/service-drop-ins-desktop-3x.jpg';
import health2xImage from '../../../../assets/services/service-health-desktop-2x.jpg';
import health3xImage from '../../../../assets/services/service-health-desktop-3x.jpg';
import premium2xImage from '../../../../assets/services/service-premium-2x.png';
import premium3xImage from '../../../../assets/services/service-premium-3x.png';
import recurring2xImage from '../../../../assets/services/service-recurring-2x.png';
import recurring3xImage from '../../../../assets/services/service-recurring-3x.png';
import sitting2xImage from '../../../../assets/services/service-sitting-desktop-2x.jpg';
import sitting3xImage from '../../../../assets/services/service-sitting-desktop-3x.jpg';
import training2xImage from '../../../../assets/services/service-training-desktop-2x.jpg';
import training3xImage from '../../../../assets/services/service-training-desktop-3x.jpg';
import walk2xImage from '../../../../assets/services/service-walk-desktop-2x.jpg';
import walk3xImage from '../../../../assets/services/service-walk-desktop-3x.jpg';
import wellness2xImage from '../../../../assets/services/service-wellness-desktop-2x.jpg';
import wellness3xImage from '../../../../assets/services/service-wellness-desktop-3x.jpg';
import petted2xImage from '../../../../assets/services/service-petted-desktop-2x.jpg';
import petted3xImage from '../../../../assets/services/service-petted-desktop-3x.jpg';
import furmacy2xImage from '../../../../assets/services/service-furmacy-desktop.jpeg';
import dfa2xImage from '../../../../assets/services/service-dfa-desktop-2x.jpg';
import dfa3xImage from '../../../../assets/services/service-dfa-desktop-3x.jpg';
import cfa2xImage from '../../../../assets/services/service-cfa-desktop-2x.jpg';
import wwtv2xImage from '../../../../assets/services/wwtvimage.jpg';

import leash2xSidePhotoImage from '../../../../assets/services/side-photos/leash-2x.jpg';
import tugToy2xSidePhotoImage from '../../../../assets/services/side-photos/tug-toy-2x.jpg';
import reats2xSidePhotoImage from '../../../../assets/services/side-photos/reats-2x.jpg';
import foodBowl2xSidePhotoImage from '../../../../assets/services/side-photos/food-bowl-2x.jpg';
import stethoscope2xSidePhotoImage from '../../../../assets/services/side-photos/stethoscope-2x.jpg';
import wagBall2xSidePhotoImage from '../../../../assets/services/side-photos/wag-ball-2x.png';
import wagExpandableBowl2xSidePhotoImage from '../../../../assets/services/side-photos/wag-expandable-bowl-2x.png';
import catToy2x from '../../../../assets/services/side-photos/cat-toy 2x.png';
import catToy3x from '../../../../assets/services/side-photos/cat-toy 3x.png';

import pettedLogo from '../../../../assets/services/petted-logo.svg';
import wagWellnessLogo from '../../../../assets/services/wag-wellness-logo.svg';
import furmacyLogo from '../../../../assets/services/furmacy-logo.svg';
import dfaLogo from '../../../../assets/services/dfa-logo.png';
import cfaLogo from '../../../../assets/services/cfaLogo.svg';
import woofWoofTvLogo from '../../../../assets/services/woofwooftvlogo.svg';

const services = [
  {
    name: 'On-demand Dog Walking',
    url: ServiceUrls.Walking,
    IconComponent: ServiceOnDemandWalkCircleIcon,
    src: walk2xImage,
    srcSet: `${walk2xImage} 2x, ${walk3xImage} 3x`,
    description: (
      <Typography>
        Instantly book trusted, local, 5-star dog walkers in your neighborhood,
        then track the walk via GPS.
      </Typography>
    ),
    sidePhoto: (
      <>
        <LazyImage
          src={leash2xSidePhotoImage}
          alt="side photo leash"
          width={['100px', null, '216px']}
          height={['140px', null, '300px']}
          position="absolute"
          right={['-44px', null, 0]}
          top={['-30px', null, '-180px']}
        />
      </>
    ),
  },
  {
    name: 'Recurring Dog Walks',
    url: ServiceUrls.Walking,
    IconComponent: ServiceRecurringWalkCircleIcon,
    src: recurring2xImage,
    srcSet: `${recurring2xImage} 2x, ${recurring3xImage} 3x`,
    description: (
      <Typography>
        {'Select the dates and times that best suit your pup\'s needs for worry-free pet care.'}
      </Typography>
    ),
    sidePhoto: (
      <>
        <LazyImage
          src={tugToy2xSidePhotoImage}
          alt="side photo tug toy"
          width={['80px', null, '216px']}
          height={['100px', null, '300px']}
          position="absolute"
          left={[0]}
          top={['-40px', null, '-100px']}
        />
      </>
    ),
  },
  {
    name: 'Wag! Premium',
    url: ServiceUrls.Premium,
    IconComponent: ServicePremiumIcon,
    src: premium2xImage,
    srcset: `${premium2xImage} 2x, ${premium3xImage} 3x`,
    description: (
      <Typography>
        Enjoy zero service fees, discounts on all pet care services, and FREE Vet Chat.
        Plus, get VIP customer care service.
      </Typography>
    ),
    sidePhoto: (
      <>
        <LazyImage
          src={reats2xSidePhotoImage}
          alt="side photo treats"
          width={['130px', null, '250px']}
          height={['150px', null, '300px']}
          position="absolute"
          right={0}
          top={['-40px', null, '-100px']}
        />
      </>
    ),
  },
  {
    name: 'Drop-In',
    url: ServiceUrls.DropIn,
    IconComponent: ServiceDropInCircleIcon,
    src: dropIn2xImage,
    srcSet: `${dropIn2xImage} 2x, ${dropIn3xImage} 3x`,
    description: (
      <>
        <Typography>
          A quick home visit for dogs and cats who love having friends over.
        </Typography>
      </>
    ),
  },
  {
    name: 'Training',
    url: ServiceUrls.Training,
    IconComponent: ServiceTrainingCircleIcon,
    src: training2xImage,
    srcSet: `${training2xImage} 2x, ${training3xImage} 3x`,
    description: (
      <Typography>
        One-on-one in-person training sessions.
      </Typography>
    ),
    sidePhoto: (
      <>
        <LazyImage
          src={foodBowl2xSidePhotoImage}
          alt="side photo food bowl"
          width={['80px', null, '164px']}
          height={['210px', null, '400px']}
          position="absolute"
          left={0}
          top={['488px', null, '288px']}
        />
      </>
    ),
  },
  {
    name: 'Boarding',
    url: ServiceUrls.Boarding,
    IconComponent: ServiceBoardingCircleIcon,
    src: boarding2xImage,
    srcSet: `${boarding2xImage} 2x, ${boarding3xImage} 3x`,
    description: (
      <Typography>
        A sleepover for your pet in a Pet Caregivers home.
      </Typography>
    ),
    sidePhoto: null,
  },
  {
    name: 'Sitting',
    url: ServiceUrls.Sitting,
    IconComponent: ServiceSittingCircleIcon,
    src: sitting2xImage,
    srcSet: `${sitting2xImage} 2x, ${sitting3xImage} 3x`,
    description: (
      <Typography>
        A sleepover for your pet in the comfort of your own home.
      </Typography>
    ),
  },
  {
    name: 'Vet Chat',
    url: ServiceUrls.Health,
    IconComponent: ServiceHealthCircleIcon,
    src: health2xImage,
    srcSet: `${health2xImage} 2x, ${health3xImage} 3x`,
    description: (
      <Typography>
        Chat with a licensed pet expert 24/7.
      </Typography>
    ),
    sidePhoto: null,
  },
  {
    name: 'Pet Insurer',
    nameBlock: (
      <>
        <LazyImage
          src={pettedLogo}
          alt="Pet Insurer logo"
          width="208px"
          height="48px"
        />
      </>
    ),
    url: ServiceUrls.Petted,
    IconComponent: () => null,
    src: wellness2xImage,
    srcSet: `${wellness2xImage} 2x, ${wellness3xImage} 3x`,
    description: (
      <Typography>
        Compare and purchase
        <Link
          href={ServiceUrls.PetInsurer}
          target="_blank"
          color="wagGreen"
          fontWeight="bold"
        >
          &nbsp;top-rated pet insurance plans&nbsp;
        </Link>
        with a simple click.
      </Typography>
    ),
    sidePhoto: (
      <>
        <LazyImage
          src={stethoscope2xSidePhotoImage}
          alt="side photo stethoscope"
          width={['80px', null, '200px']}
          height={['210px', null, '400px']}
          position="absolute"
          right={0}
          top={['-72px', null, '-240px']}
        />
      </>
    ),
  },
  {
    name: 'Wag! Wellness Plans',
    nameBlock: (
      <>
        <LazyImage
          alt="Wag! Wellness logo"
          src={wagWellnessLogo}
          width="208px"
          height="48px"
        />
      </>
    ),
    url: ServiceUrls.WellnessPlans,
    IconComponent: () => null,
    src: petted2xImage,
    srcSet: `${petted2xImage} 2x, ${petted3xImage} 3x`,
    sidePhoto: (
      <>
        <LazyImage
          src={wagExpandableBowl2xSidePhotoImage}
          alt="side photo Wag! expandable bowl"
          width={['80px', null, '314px']}
          height={['210px', null, '314px']}
          position="absolute"
          left={[0, null, '-70px']}
          top={['-72px', null, '-240px']}
          imageProps={{
            style: {
              objectFit: 'contain',
            },
          }}
        />
      </>
    ),
    description: (
      <Typography>
        Simple and affordable
        <Link
          href={ServiceUrls.WellnessPlans}
          target="_blank"
          color="wagGreen"
          fontWeight="bold"
        >
          &nbsp;routine wellness plans&nbsp;
        </Link>
        to cover vaccines, flea & tick, meds, and more.
      </Typography>
    ),
  },
  {
    name: 'Furmacy',
    nameBlock: (
      <>
        <LazyImage
          alt="Furmacy logo"
          src={furmacyLogo}
          width="208px"
          height="48px"
        />
      </>
    ),
    url: ServiceUrls.Furmacy,
    IconComponent: () => null,
    src: furmacy2xImage,
    srcSet: `${furmacy2xImage} 2x`,
    sidePhoto: (
      <>
        <LazyImage
          src={wagBall2xSidePhotoImage}
          alt="side photo Wag! ball"
          width={['80px', null, '220px']}
          height={['210px', null, '220px']}
          position="absolute"
          right="-30px"
          top={['-72px', null, '-240px']}
          imageProps={{
            style: {
              objectFit: 'contain',
            },
          }}
        />
      </>
    ),
    description: (
      <Typography>
        We make secure, user-friendly software for vet staff to e-prescribe
        to any pharmacy and manage script requests in one place.
      </Typography>
    ),
  },
  {
    name: 'DogFoodAdvisor',
    nameBlock: (
      <>
        <LazyImage
          alt="Dog Food Advisor logo"
          src={dfaLogo}
          width="224px"
          height="48px"
        />
      </>
    ),
    url: ServiceUrls.DogFoodAdvisor,
    IconComponent: () => null,
    src: dfa2xImage,
    srcSet: `${dfa2xImage} 2x, ${dfa3xImage} 3x`,
    description: (
      <Typography>
        Search and find
        <Link
          href={ServiceUrls.DogFoodAdvisor}
          target="_blank"
          color="wagGreen"
          fontWeight="bold"
        >
          &nbsp;the best dog food&nbsp;
        </Link>
        for your dog based on unbiased dog food
        reviews and ratings by brand.
      </Typography>
    ),
  },
  {
    name: 'CatFoodAdvisor',
    nameBlock: (
      <>
        <LazyImage
          alt="Cat Food Advisor logo"
          src={cfaLogo}
          width="224px"
          height="48px"
        />
      </>
    ),
    sidePhoto: (
      <>
        {/* Mobile photo */}
        <LazyImage
          src={catToy2x}
          display={['block', 'none']}
          alt="side photo Wag! ball"
          width={['80px', null, '220px']}
          height={['210px', null, '220px']}
          position="absolute"
          right={['-16px', 'unset']}
          top={['-72px', null, '-240px']}
          imageProps={{
            style: {
              objectFit: 'contain',
            },
          }}
        />
        {/* Desktop photo */}
        <LazyImage
          src={catToy3x}
          display={['none', 'block']}
          alt="side photo Wag! ball"
          width={['80px', null, '220px']}
          height={['210px', null, '220px']}
          position="absolute"
          left={['unset', '-34px']}
          top={['-72px', null, '-240px']}
          imageProps={{
            style: {
              objectFit: 'contain',
            },
          }}
        />
      </>
    ),
    IconComponent: () => null,
    url: ServiceUrls.CatFoodAdvisor,
    src: cfa2xImage,
    srcSet: `${cfa2xImage} 2x`,
    description: (
      <Typography>
        Search and browse the best food for your cat based on unbiased,
        science-backed reviews and ratings.
      </Typography>
    ),
  },
  {
    name: 'WoofWoofTV',
    nameBlock: (
      <>
        <LazyImage
          alt="WoofWoofTV logo"
          src={woofWoofTvLogo}
          width="224px"
          height="88px"
        />
      </>
    ),
    IconComponent: () => null,
    url: ServiceUrls.WoofWoofTV,
    src: wwtv2xImage,
    srcSet: `${wwtv2xImage} 2x`,
    description: (
      <Typography>
        One of the largest social media platforms providing
        pet content to their engaged community of pet lovers.
      </Typography>
    ),
  },
];

export const ServicesSection = () => (
  <Box
    overflow="hidden"
    px="unset"
  >
    <Box
      mb={[7, null, 10]}
    >
      <Typography
        color="#474747"
        mb={0}
        textAlign="center"
        variant="h2"
      >
        Wag! Platform
      </Typography>
      <Typography
        fontWeight="normal"
        my={[1, null, 3]}
        textAlign="center"
        variant="h3"
      >
        Everything a happy pet needs
      </Typography>
    </Box>
    {services.map(({
      url,
      IconComponent,
      name,
      nameBlock,
      description,
      src,
      srcSet,
      sidePhoto,
    }, i) => (
      <Flex
        key={name}
        mb={['75px', null, '130px']}
        flexDirection={['column', null, 'row']}
        position="relative"
        px={[2, 7]}
      >
        <Flex
          flex={2}
          order={isEven(i) ? [2, null, 1] : 2}
          justifyContent={isEven(i) ? 'flex-end' : 'flex-start'}
        >
          <Box
            data-aos="fade-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
          >
            {sidePhoto}
          </Box>
          <Link
            href={url}
            target="_blank"
            aria-label={name}
          >
            <LazyImage
              srcSet={srcSet}
              src={src}
              imageProps={{
                borderRadius: 3,
              }}
              alt={`${name} service`}
              height={['320px', null, '500px']}
              width={['354px', '700px', '700px']}
              zIndex={0}
            />
          </Link>
        </Flex>
        <Flex
          order={isEven(i) ? [1, null, 2] : 1}
          alignItems={isEven(i) ? 'flex-start' : 'flex-end'}
          flex={1}
          flexDirection="column"
          justifyContent="center"
          mb={[3, null, 0]}
          position="relative"
          px={[0, null, 4]}
          textAlign={isEven(i) ? 'left' : 'right'}
        >
          <Box
            data-aos="fade-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
          >
            <Link
              href={url}
              target="_blank"
              aria-label={name}
            >
              <IconComponent
                position={['relative', null, 'absolute']}
                mb={[2, null, 0]}
                ml={isEven(i) ? [0, null, '-30px'] : 0}
                mr={isEven(i) ? 0 : [0, null, '-30px']}
                left={isEven(i) ? 0 : 'auto'}
                right={isEven(i) ? 'auto' : 0}
                top={[0, null, '60px']}
                height={['56px', null, '110px']}
                width={['56px', null, '111px']}
                zIndex={1}
              />
            </Link>
          </Box>

          <Typography
            variant="h3"
            fontFamily="muliBold"
            fontSize={['18px', null, '24px']}
            m={0}
          >
            <Link
              href={url}
              color="wagGreen"
              textTransform="uppercase"
              target="_blank"
              aria-label={name}
            >
              {nameBlock || name}
            </Link>
          </Typography>
          <Box
            maxWidth={['100%', null, '340px']}
          >
            {description}
          </Box>
        </Flex>
      </Flex>
    ))}
  </Box>
);
